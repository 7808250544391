import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useWindowSize } from '../../hooks/useWindowSize';
import { media } from '../../utils/media-queries';

const ThumbnailVideo = ({ src, slug, onClick, onMouseOver, className, onMouseEnter, onMouseLeave, poster, url }) => {
  const ref = useRef();

  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  const [pos, setPos] = useState();
  const [vidLoaded, setVidLoaded] = useState(false);

  useEffect(() => {
    setIsMobile(size.width < 768);
    ref.current.currentTime += Math.random() * 10;

    setPos(isMobile ? { x: (size.width - 150) * Math.random() } : { y: (size.height - 200) * Math.random() });
  }, [isMobile, size]);

  return (
    <Link to={`/${slug}/`}>
      <StyledThumbnailVideo
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
        animate={pos}
        transition={{ duration: 0.1 }}
      >
        <video
          ref={ref}
          src={src}
          loop
          muted
          playsInline
          className="thumbnail"
          data-loaded={vidLoaded ? 'loaded' : 'loading'}
          onLoadedData={() => setVidLoaded(true)}
        />
        <AnimatePresence>
          {!(vidLoaded && ref.current) && (
            <motion.figure className="preview-img" exit={{ opacity: 0 }}>
              {poster ? <GatsbyImage image={poster} alt="Background Video Preview" /> : <img src={url} alt="Video Preview" />}
            </motion.figure>
          )}
        </AnimatePresence>
      </StyledThumbnailVideo>
    </Link>
  );
};

const StyledThumbnailVideo = styled(motion.div)`
  width: var(--sp-13);
  height: var(--sp-13);
  margin: var(--sp-3) 0;
  overflow: hidden;
  cursor: none;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .preview-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    pointer-events: none;
  }

  &.hide {
    transition: 0.3s;
    opacity: 0.3;
  }

  @media ${media.M} {
    margin: 0 var(--sp-5);
    width: 18rem;
    height: 18rem;
  }
`;

ThumbnailVideo.propTypes = {
  src: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string,
  poster: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default ThumbnailVideo;

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from '../../utils/media-queries';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useTransformScroll } from '../../hooks/useTransformScroll';
import { ease } from '../../utils/easing';

const Slider = ({ children }) => {
  const sliderRef = useRef(null);

  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState();
  const [scrollLeft, setScrollLeft] = useState();

  const handleDown = (e) => {
    setIsDown(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleLeave = () => {
    setIsDown(false);
  };

  const handleUp = () => {
    setIsDown(false);
  };

  const handleMove = (e) => {
    if (!isDown) return;
    const x = e.pageX - sliderRef.current.offsetLeft;
    const distance = (x - startX) * 2;
    sliderRef.current.scrollLeft = scrollLeft - distance;
  };

  const size = useWindowSize();
  const [height, setHeight] = useState(false);

  useEffect(() => {
    setHeight(size.height);
  }, [size]);

  return (
    <StyledSlider
      style={{ height }}
      ref={sliderRef}
      onMouseDown={handleDown}
      onMouseLeave={handleLeave}
      onMouseUp={handleUp}
      onMouseMove={handleMove}
      onWheel={useTransformScroll}
      key="videoslider"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4, ease: ease.outSmooth }}
    >
      <div className="wrapper">{children}</div>
    </StyledSlider>
  );
};

const StyledSlider = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
  padding-top: var(--sp-8);
  padding-bottom: var(--sp-8);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    * {
      flex-shrink: 0;
    }
  }

  @media ${media.M} {
    padding-top: 0;
    padding-bottom: 0;

    .wrapper {
      flex-direction: row;
    }

    .wrapper a:last-child {
      padding-right: var(--sp-13);
    }
  }
`;

Slider.propTypes = {
  children: PropTypes.node,
};

export default Slider;

/* eslint-disable react/display-name */
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useWindowSize } from '../../hooks/useWindowSize';

const BackgroundVideo = React.forwardRef(({ src, initial, animate, transition, poster, url }, ref) => {
  const size = useWindowSize();

  const [vidLoaded, setVidLoaded] = useState(false);
  const [height, setHeight] = useState(false);

  useEffect(() => {
    setHeight(size.height);
  }, [size]);

  return (
    <StyledBackgroundVideo initial={initial} animate={animate} transition={transition} style={{ height }}>
      <video
        ref={ref}
        src={src}
        loop
        muted
        playsInline
        autoPlay
        data-loaded={vidLoaded ? 'loaded' : 'loading'}
        onLoadedData={() => setVidLoaded(true)}
      />
      {!vidLoaded && (
        <figure className="bg-preview-img">
          {poster ? <GatsbyImage image={poster} alt="Background Video Preview Image" /> : <img src={url} alt="Background Video Preview Image" />}
        </figure>
      )}
    </StyledBackgroundVideo>
  );
});

const StyledBackgroundVideo = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  filter: blur(4px);

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .bg-preview-img {
    position: absolute;
    top: 0;
    left: 0;

    .gatsby-image-wrapper {
      width: 100vw;
      height: 100vh;
    }
  }
`;

BackgroundVideo.propTypes = {
  src: PropTypes.string,
  url: PropTypes.string,
  poster: PropTypes.string,
  initial: PropTypes.object,
  animate: PropTypes.object,
  transition: PropTypes.object,
};

export default BackgroundVideo;

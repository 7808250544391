import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/organisms/seo';
import BackgroundVideo from '../components/atoms/backgroundVideo';
import VideoSlider from '../components/molecules/videoSlider';
import Cursor from '../components/atoms/cursor';
import ThumbnailVideo from '../components/atoms/thumbailVideo';
import { useWindowSize } from '../hooks/useWindowSize';

const IndexPage = ({ data }) => {
  const { previewShowreel, _rawProjects, previewImage } = data.videos.nodes[0];

  const [currVid, setCurrVid] = useState();
  const [currVidI, setCurrVidI] = useState(null);
  const [currSrc, setCurrSrc] = useState(null);
  const [currTime, setCurrTime] = useState(0);
  const [showReel, setShowReel] = useState(true);
  const [setClass, setSetClass] = useState('');

  const ref = createRef();

  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(size.width < 768);

    if (currVid) {
      setCurrSrc(currVid.getAttribute('src'));
      setCurrTime(currVid.currentTime);
    }
  }, [size.width, currVid, showReel]);

  useEffect(() => {
    if (ref.current && ref.current.getAttribute('src') === currSrc && !showReel) {
      ref.current.currentTime = currTime;
    }
  }, [ref, showReel, currSrc, currTime]);

  return (
    <>
      <SEO />
      {!isMobile && (
        <Cursor
          initial={{ opacity: 0 }}
          animate={{ opacity: !showReel ? 1 : 0 }}
          text={!showReel && `${_rawProjects[currVidI].title} ${_rawProjects[currVidI].subTitle ? `— ${_rawProjects[currVidI].subTitle}` : ''}`}
          subtitle={!showReel && _rawProjects[currVidI].mouseover}
        />
      )}

      {/* Showreel */}
      <BackgroundVideo
        animate={{ opacity: currVidI === null ? 0.2 : 0 }}
        src={previewShowreel}
        poster={previewImage.asset.gatsbyImageData}
        url={previewImage.asset.url}
      />

      <BackgroundVideo
        ref={ref}
        initial={{ opacity: 0 }}
        animate={{ opacity: !showReel ? [0, 0.2] : [0, 0] }}
        transition={{ delay: 0.1 }}
        src={currSrc}
        url={currVidI && _rawProjects[currVidI].previewImage.asset.url}
        poster={currVidI && _rawProjects[currVidI].previewImage.asset.gatsbyImageData}
      />

      <VideoSlider>
        {_rawProjects.map((project, i) => (
          <ThumbnailVideo
            key={i}
            className={currVidI !== i && setClass}
            src={project.previewVideo}
            poster={project.previewImage?.asset.gatsbyImageData}
            url={project.previewImage?.asset.url}
            slug={project.slug?.current}
            onMouseOver={() => setSetClass('hide')}
            onMouseEnter={(e) => {
              setShowReel(false);
              setCurrVidI(i);
              setCurrVid(e.target);
              if (e.target.getAttribute('data-loaded') !== 'loading') e.target.play();
            }}
            onMouseLeave={() => {
              setShowReel(true);
              setCurrVidI(null);
              setSetClass('');
              setTimeout(() => {
                if (currVid && currVid.getAttribute('data-loaded') !== 'loading') currVid?.pause();
              }, 100);
            }}
          />
        ))}
      </VideoSlider>
    </>
  );
};

export const query = graphql`
  query Videos {
    videos: allSanityProjectsPage {
      nodes {
        showreel
        previewShowreel
        previewImage {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        _rawProjects(resolveReferences: { maxDepth: 5 })
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
